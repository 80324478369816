<template>
  <div class="q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['greasySkin'])">
      <single-answer-question
        title="label.greasySkin"
        :value="greasySkin"
        :options="greasySkinAmountOptions"
        dysfunction="skin-oiliness"
        @input="onFieldChange('greasySkin', $event)"
      />
    </question-with-error-wrap>

    <fade-in-transition-wrap>
      <question-with-error-wrap
        v-if="hasSomeGreasiness"
        class="q-mt24 q-mt32-md"
        :error="getFieldsError(['openFacialPores'])"
      >
        <single-answer-question
          id="open-facial-pores"
          title="label.openFacialPores"
          :value="openFacialPores"
          :options="facialPoresAmountOptions"
          dysfunction="skin-oiliness"
          @input="onFieldChange('openFacialPores', $event)"
        />
      </question-with-error-wrap>
    </fade-in-transition-wrap>

    <question-divider
      v-if="isQuestionDividerVisible"
      class="q-mt24 q-mb32 q-mt32-md q-mb40-md"
      title="questionnaire.tellUsMore.title"
    />

    <fade-in-transition-wrap>
      <question-with-error-wrap
        v-if="hasSomeGreasiness"
        class="q-mt24 q-mt32-md"
        :error="getFieldsError(['greasySkinLocations'])"
      >
        <face-zone-single-select
          id="greasy-skin-locations"
          title="label.greasySkinLocations"
          :gender="gender"
          :value="greasySkinLocations"
          :options="greasySkinLocationsOptions"
          field-name="oiliness"
          @input="onFieldChange('greasySkinLocations', $event)"
        />
      </question-with-error-wrap>
    </fade-in-transition-wrap>
  </div>
</template>

<script>
import QuestionDivider from '@/modules/questionnaire/components/common/QuestionDivider';
import FadeInTransitionWrap from '@/components/wrappers/FadeInTransitionWrap';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import FaceZoneSingleSelect from '@/modules/questionnaire/new-design-components/questions/face-zone-input/FaceZoneSingleSelect';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import { flagAiSelectedOption } from '@/modules/questionnaire/api/helpers';

import { AMOUNT_OPTIONS } from '@/modules/questionnaire/constants/questions';
import { GREASY_SKIN_LOCATION_OPTIONS } from '@/modules/questionnaire/constants/steps/skinOiliness';

export default {
  name: 'OilinessTemplate',
  components: {
    FadeInTransitionWrap,
    QuestionDivider,
    QuestionWithErrorWrap,
    SingleAnswerQuestion,
    FaceZoneSingleSelect
  },
  mixins: [stepTemplateMixin],
  props: {
    gender: {
      type: String,
      required: true
    },
    greasySkin: {
      type: String,
      default: ''
    },
    greasySkinAiSelected: {
      type: String,
      default: ''
    },
    openFacialPores: {
      type: String,
      default: ''
    },
    openFacialPoresAiSelected: {
      type: String,
      default: ''
    },
    greasySkinLocations: {
      type: String,
      default: ''
    },
    greasySkinLocationsAiSelected: {
      type: String,
      default: ''
    },
    hasSomeGreasiness: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    greasySkinAmountOptions() {
      return flagAiSelectedOption(AMOUNT_OPTIONS, this.greasySkinAiSelected);
    },
    facialPoresAmountOptions() {
      return flagAiSelectedOption(AMOUNT_OPTIONS, this.openFacialPoresAiSelected);
    },
    greasySkinLocationsOptions() {
      return flagAiSelectedOption(GREASY_SKIN_LOCATION_OPTIONS, this.greasySkinLocationsAiSelected);
    },
    isQuestionDividerVisible() {
      return this.hasSomeGreasiness && !this.greasySkinLocationsAiSelected;
    }
  }
};
</script>
